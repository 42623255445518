import React from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';

function MultiLineInput() {
    const [resumeData, setResumeData] = React.useState({
        pdfName: "New pdf",
        pdf: ""
    });
    const [responseData, setResponseData] = React.useState("");

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            convertToByteArray(file);
        }
    };

    const convertToByteArray = (file) => {
        const reader = new FileReader();

        reader.onload = () => {
            const arrayBuffer = reader.result;
            console.log("Array Biuffer is",arrayBuffer)
            const byteArray = new Uint8Array(arrayBuffer);
            console.log("byet array",byteArray)
            const decoder = new TextDecoder('utf-8');
            setResumeData({
                pdfName: file.name,
                pdf: decoder.decode(byteArray)
            });
          
        };

        reader.readAsArrayBuffer(file);
    };

    const handleUpload = async () => {
        try {
            const response = await axios.post("http://localhost:8080/resume/addResume", resumeData);
            setResponseData(response.data.pdf);
       
        } catch (error) {
            console.error('Error uploading resume:', error);
            // Handle error and provide user feedback
        }
    };

    // const handleGeneratePDF = () => {
    //     if (responseData) {

    //         const byteArray = new Uint8Array(responseData);
    //         const blob = new Blob([byteArray], { type: 'application/pdf' });
    //         console.log("Byte array",byteArray)
    //         console.log("response data",responseData)
    //         const downloadLink = document.createElement('a');
    //         downloadLink.href = URL.createObjectURL(blob);
    //         downloadLink.download = 'generated-pdf.pdf';
    //         document.body.appendChild(downloadLink);
    //         downloadLink.click();
    //         document.body.removeChild(downloadLink);
    //     } else {
    //         console.error('No PDF data received.');

    //     }
    // };


    return (
        <div>
            <label htmlFor="pdf-file">Upload PDF file:</label>
            <input type="file" id="pdf-file" accept=".pdf" onChange={handleFileChange} />
            <button className='border-2 bg-green-200 rounded-lg p-2' onClick={handleUpload}>Upload data</button>
            {/* <button className='border-2 bg-blue-200 rounded-lg p-2' onClick={handleGeneratePDF}>Generate PDF</button> */}
        </div>
    );
}

export default MultiLineInput;
