// ContactForm.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ContactForm = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        console.log(formData);
        onClose(); // Close form after submission
    };

    return (
        <motion.div
            className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-transform ${isOpen ? 'scale-100' : 'scale-0'}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: isOpen ? 1 : 0 }}
            transition={{ duration: 0.3 }}
        >
            <motion.div
                className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg"
                initial={{ y: '-100vh' }}
                animate={{ y: isOpen ? '0' : '-100vh' }}
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            >
                <div className='flex justify-end'>
                    <button onClick={onClose} className=" top-4 text-2xl right-4 text-red-600 transition-all hover:scale-150 bg-danger">
                        &times;
                    </button>
                </div>
                <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Message</label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded"
                            rows="4"
                            required
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="bg-[#1462dd] text-white py-2 px-6 rounded-lg hover:bg-[#104a8a] transition-colors"
                    >
                        Send Message
                    </button>
                </form>
            </motion.div>
        </motion.div>
    );
};

export default ContactForm;
