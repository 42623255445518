import './App.css';
import Navbar from './Components/Navbar';
import ViewJob from './Components/ViewJob';
import EditJob from './Components/EditJob';
import Test from './Components/Test';
import Quiz from './Components/Quiz';
import CareerLandingPage from './Pages/CareerLandingPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './Auth/AuthContext';  // Import AuthProvider
import PostedJobs from './Pages/PostedJobs';
import PostNewJob from './Pages/PostNewJob'
import ViewApplication from './Pages/ViewApplication';
import ApplyJob from './Pages/ApplyJob';
import ApplicantList from './Pages/ApplicantList';
import ApplicantsScore from './Pages/ApplicantsScore'
import RdvisionLandingPage from './Pages/RdvisionLandingPage'
import Footer from './Components/Footer'
function App() {
  return (
    <div className="App bg-blue-50">
      <Router>
        <AuthProvider>
          <Navbar />
          <Routes>
            <Route exact path="/careers" element={<CareerLandingPage />} />
            <Route exact path="/post" element={<PostNewJob />} />
            <Route exact path="/aplicantList" element={<ApplicantList />} />
            <Route exact path="/postedJobs" element={<PostedJobs />} />
            <Route exact path="/apply/:id" element={<ApplyJob />} />
            <Route exact path="/job/:id" element={<ViewJob />} />
            <Route exact path="/application/:id" element={<ViewApplication />} />
            <Route exact path="/edit/:id" element={<EditJob />} />
            <Route exact path="/test" element={<Test />} />
            <Route exact path="/quiz" element={<Quiz />} />
            <Route exact path="/score" element={<ApplicantsScore/>} />
            <Route exact path="/" element={<RdvisionLandingPage/>} />
          </Routes>
          <Footer/>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
