import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../Auth/AuthContext';

function ApplyJob() {
    const { url, token } = useAuth(0);
    const navigate = useNavigate();
    const { id: jobId } = useParams();  // Destructure id from useParams
    const [applicationDetails, setApplicationDetails] = useState({
        applicantFullName: "",
        email: "",
        highestEducation: "",
        collegeName: "",
        mobileNumber: "",
        degreeName: "",
        graduationYear: "",
        cgpaGraduation: "",
        resumeLink: "",
        introVideoLink: "",
        portfolioLink: "",
        currentLocation: "",
        experienceType: "",
        experienceInYear: "",
        about: "",
        highSchoolPercentage: "",  // New field
        twelfthPercentage: "",     // New field
        jobModel: {
            jobId: jobId
        }
    });

    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setApplicationDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const applicationInfo = () => toast("You Can Apply only One Job in 3 Months");

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${url}/application/addApplication`, applicationDetails);
            if (response.data) {
                handleOnClick();
                setApplicationDetails({
                    applicantFullName: "",
                    email: "",
                    highestEducation: "",
                    collegeName: "",
                    mobileNumber: "",
                    degreeName: "",
                    graduationYear: "",
                    cgpaGraduation: "",
                    resumeLink: "",
                    introVideoLink: "",
                    portfolioLink: "",
                    currentLocation: "",
                    experienceType: "",
                    experienceInYear: "",
                    about: "",
                    highSchoolPercentage: "",  // Reset new field
                    twelfthPercentage: "",     // Reset new field
                    jobModel: {
                        jobId: jobId
                    }
                });
                setTimeout(() => {
                    navigate("/postedJobs");
                }, 3000);
            } else {
                applicationInfo();
            }
        } catch (error) {
            console.error('Error submitting application:', error);
            applicationInfo();
        }
    };

    const handleOnClick = () => toast("Application Submitted");

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center py-8 px-4 sm:px-6 lg:px-8">
            <section className="text-gray-800 body-font relative bg-white shadow-lg rounded-lg p-6 sm:p-8 lg:w-2/3 w-full mx-auto mb-8">
                <div className="flex flex-col text-center w-full mb-8">
                    <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-gray-900 animate-pulse">Apply for Frontend Developer</h1>
                </div>
                <form className="space-y-4" onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {Object.entries({
                            applicantFullName: "Full Name",
                            mobileNumber: "Mobile Number",
                            email: "Email",
                            highestEducation: "Highest Education",
                            degreeName: "Degree Name",
                            collegeName: "College Name",
                            highSchoolPercentage: "High School Percentage",
                            twelfthPercentage: "12th Percentage",
                            cgpaGraduation: "CGPA in Graduation",
                            graduationYear: "Graduation Year",
                            resumeLink: "Resume Link",
                            introVideoLink: "Introduction Video Link",
                            portfolioLink: "Portfolio Link",
                            currentLocation: "Current Location",
                            experienceType: "Experience Type",
                            experienceInYear: "Experience (in Years)",
                        }).map(([key, label]) => (
                            <div key={key} className="relative">
                                <label htmlFor={key} className="text-sm font-medium text-gray-600">{label}</label>
                                <input
                                    onChange={handleOnChange}
                                    type={key.includes("Percentage") ? "number" : "text"}
                                    id={key}
                                    name={key}
                                    value={applicationDetails[key]}
                                    step={key.includes("Percentage") ? ".1" : undefined}
                                    max={key.includes("Percentage") ? "100" : undefined}
                                    min={key.includes("Percentage") ? "0" : undefined}
                                    className="w-full bg-gray-100 border border-gray-300 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 text-gray-700 py-2 px-3 transition duration-300 ease-in-out transform hover:scale-105 focus:scale-105"
                                    required={key !== "resumeLink" && key !== "introVideoLink" && key !== "portfolioLink"}
                                />
                            </div>
                        ))}
                        <div className="relative col-span-1 sm:col-span-2">
                            <label htmlFor="about" className="text-sm font-medium text-gray-600">About Yourself</label>
                            <textarea
                                onChange={handleOnChange}
                                id="about"
                                name="about"
                                value={applicationDetails.about}
                                rows="4"
                                className="w-full bg-gray-100 border border-gray-300 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 text-gray-700 py-2 px-3 transition duration-300 ease-in-out transform hover:scale-105 focus:scale-105"
                                required
                            ></textarea>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="w-full py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition duration-300 ease-in-out font-medium transform hover:scale-105 focus:scale-105"
                    >
                        Submit Application
                    </button>
                </form>
                <ToastContainer />
            </section>
        </div>
    );
}

export default ApplyJob;
