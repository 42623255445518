import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useAuth } from '../Auth/AuthContext';

function ViewJob() {
    const [job, setJob] = useState({});
    const jobId = useParams();
    const [description, setDescription] = useState([]);
    const { url } = useAuth();
    
    useEffect(() => {
        loadJob();
    }, []);

    const loadJob = async () => {
        try {
            const response = await axios.get(`${url}/jobs/getJobById/${jobId.id}`);
            setJob(response.data);
            setDescription(response.data.jobDescription.split("\n"));
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className='flex flex-col items-center px-4 py-5 md:px-8 lg:px-16'>
            <div className='text-xl font-medium mb-2 animate-fadeIn'>{job.jobTitle}</div>
            <div className='text-sm text-gray-600 mb-2 animate-fadeIn delay-100'>{job.dateOfPost}</div>
            <div className='text-sm text-gray-600 mb-2 animate-fadeIn delay-200'>{job.requiredSkills}</div>
            <div className='text-sm text-gray-600 mb-2 animate-fadeIn delay-300'>{job.experienceLevel}</div>
            <div className='text-sm text-gray-600 mb-2 animate-fadeIn delay-400'>{job.requiredDegree}</div>
            <div className='text-xl font-semibold mt-4 mb-2 animate-fadeIn delay-500'>Job Description:</div>
            <div className='text-sm text-gray-700 leading-6 text-justify animate-fadeIn delay-600'>
                {description.map((line, index) => (
                    <p key={index} className="mb-2">{line}</p>
                ))}
            </div>
            <div className='flex justify-center items-center w-full mt-6'>
                <NavLink
                    className='px-4 py-2 rounded-lg bg-blue-300 text-blue-800 font-semibold hover:bg-blue-500 hover:text-white transition-colors duration-300 ease-in-out'
                    to={`/apply/${job.jobId}`}
                >
                    Apply For this job
                </NavLink>
            </div>
        </div>
    );
}

export default ViewJob;
